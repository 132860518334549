import "core-js/modules/es7.object.get-own-property-descriptors";
import _typeof from "/www/html/junhao-front-charging/node_modules/@babel/runtime-corejs2/helpers/esm/typeof";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.function.name";
import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
import _defineProperty from "/www/html/junhao-front-charging/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty";
import "core-js/modules/web.dom.iterable";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { setToken, removeToken } from '@/util/auth';
import { setStore, getStore, removeStore } from '@/util/store';
import { isURL, validatenull } from '@/util/validate';
import { deepClone, encryptData } from '@/util/util';
import webiste from '@/config/website';
import { loginByUsername, getUserInfo, getMenu, getTopMenu, logout, refeshToken, getButtons, getCampusList, getRoutes, getBtnRules, recordLoginDetails, rememberPassword, unRememberPassword } from '@/api/user';
import { getDictKeyValueByCodes } from "@/api/resourceManage/common";
import { getAccountDataPermissions } from "@/api/resourceManage/common"; //使用资源系统树接口

import { asyncRoueter } from '@/router/modules/index';
import router, { resetRouter } from '@/router/router';

function addPath(ele, first) {
  var menu = webiste.menu;
  var propsConfig = menu.props;
  var propsDefault = {
    label: propsConfig.label || 'name',
    path: propsConfig.path || 'path',
    icon: propsConfig.icon || 'icon',
    children: propsConfig.children || 'children'
  };
  var icon = ele[propsDefault.icon];
  ele[propsDefault.icon] = validatenull(icon) ? menu.iconDefault : icon;
  var isChild = ele[propsDefault.children] && ele[propsDefault.children].length !== 0;
  if (!isChild) ele[propsDefault.children] = [];

  if (!isChild && first && !isURL(ele[propsDefault.path])) {
    ele[propsDefault.path] = ele[propsDefault.path] + '/index';
  } else {
    ele[propsDefault.children].forEach(function (child) {
      addPath(child);
    });
  }
}

function formatRouteList(list) {
  var source = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  var sourceArr = source;
  list.forEach(function (item) {
    if (item.children && item.children.length) {
      formatRouteList(item.children, sourceArr);
    }

    sourceArr.push(item.code);
  });
  return sourceArr;
}

function filterAsyncRoutes(routes, roles) {
  var res = [];
  routes.forEach(function (route) {
    var tmp = _objectSpread({}, route);

    if (route.code === undefined || roles.includes(route.code)) {
      if (tmp.children) {
        tmp.children = filterAsyncRoutes(tmp.children, roles);
      }

      res.push(tmp);
    }
  });
  return res;
}

var user = {
  state: {
    userInfo: getStore({
      name: 'userInfo'
    }) || [],
    permission: getStore({
      name: 'permission'
    }) || {},
    roles: [],
    menu: getStore({
      name: 'menu'
    }) || [],
    menuAll: [],
    token: getStore({
      name: 'token'
    }) || '',
    activeProject: getStore({
      name: 'activeProject'
    }) || {},
    //分页器
    pageSizeList: getStore({
      name: 'pageSizeList'
    }) || {},
    routes: [],
    btnRules: [],
    //版本信息
    systemVersion: ''
  },
  getters: {
    activeProject: function activeProject(state) {
      return state.activeProject;
    },
    getSystemVersion: function getSystemVersion(state) {
      return state.systemVersion;
    },
    pageSizeList: function pageSizeList(state) {
      return state.pageSizeList;
    }
  },
  actions: {
    //根据用户名登录
    LoginByUsername: function LoginByUsername(_ref, userInfo) {
      var commit = _ref.commit,
          dispatch = _ref.dispatch;
      return new Promise(function (resolve) {
        loginByUsername(userInfo.tenantCode, userInfo.username, userInfo.password, userInfo.type).then(function (res) {
          if (res.data.code === 200) {
            var data = res.data.data;
            commit('SET_TOKEN', data.accessToken);
            commit('SET_USERIFNO', data);
            dispatch('SET_DEFAULT_PROJECT');
            commit('DEL_ALL_TAG');
            commit('CLEAR_LOCK');
            dispatch('GET_ROUTES');
            dispatch('GET_BTNRULES');
            setStore({
              name: 'cache_account',
              content: userInfo.username
            });
            recordLoginDetails();

            if (userInfo.rememberPwd) {
              var timeStamp = new Date().getTime();
              var params = {
                username: userInfo.username,
                passwordAesCtr: encryptData(userInfo.password, timeStamp),
                timeStamp: timeStamp
              }; // if (userInfo.overdueDays) {
              //     params.overdueDays = userInfo.overdueDays
              // }

              rememberPassword(params);
            } else {
              unRememberPassword({
                username: userInfo.username
              });
            }

            resolve();
          } else {
            var _data = res.data.data;
            commit('SET_TOKEN', _data.accessToken);
            commit('SET_USERIFNO', _data);
            commit('DEL_ALL_TAG');
            commit('CLEAR_LOCK');
            resolve();
          }
        });
      });
    },
    SET_DEFAULT_PROJECT: function SET_DEFAULT_PROJECT(_ref2) {
      var commit = _ref2.commit;
      return new Promise(function (resolve) {
        getAccountDataPermissions().then(function (res) {
          var activeProject = {};
          var localProject = JSON.parse(window.localStorage.getItem('micro-activeProject')); //判断本地缓存的项目信息 是否存在于接口返回中

          var result = localProject ? res.data.content.filter(function (item) {
            return item.type === '2';
          }).some(function (item) {
            if (item.name == localProject.content.name) {
              return true;
            }
          }) : false;

          if (localProject && result) {
            //将当前项目设置为本地缓存的项目信息
            activeProject = res.data.content.filter(function (item) {
              return item.org_uuid === localProject.content.org_uuid;
            })[0];
          } else {
            //将当前项目设置为接口返回的第一个项目信息
            activeProject = res.data.content.filter(function (item) {
              return item.type === '2';
            })[0];
          }

          activeProject && commit('SET_ACTIVE_PROJECT', activeProject);
          resolve();
        });
      });
    },
    // 全局设置分页器 异常状态均处理为50条/页
    GET_PAGE_SIZE: function GET_PAGE_SIZE(_ref3) {
      var commit = _ref3.commit;
      return new Promise(function (resolve) {
        getDictKeyValueByCodes({
          codes: 'page_size'
        }).then(function (res) {
          if (res.data.code === 0) {
            var pageSizeList = res.data.content.page_size;
            pageSizeList && commit('SET_PAGE_SIZE', pageSizeList);
          } else {
            commit('SET_PAGE_SIZE', {
              50: "50条/页"
            });
          }

          resolve();
        }).catch(commit('SET_PAGE_SIZE', {
          50: "50条/页"
        }));
      });
    },
    GetButtons: function GetButtons(_ref4) {
      var commit = _ref4.commit;
      return new Promise(function (resolve) {
        getButtons().then(function (res) {
          var data = res.data.data;
          commit('SET_PERMISSION', data);
          resolve();
        });
      });
    },
    GET_BTNRULES: function GET_BTNRULES(_ref5) {
      var commit = _ref5.commit;
      return new Promise(function (reslove) {
        getBtnRules().then(function (res) {
          var btnRules = formatRouteList(res.data.data);
          commit('SET_BTNRULES', btnRules);
          reslove(btnRules);
        });
      });
    },
    GET_ROUTES: function GET_ROUTES(_ref6) {
      var commit = _ref6.commit;
      return new Promise(function (resolve) {
        getRoutes().then(function (res) {
          var roles = formatRouteList(res.data.data);
          var routes = process.env.NODE_ENV === 'development' ? asyncRoueter : filterAsyncRoutes(asyncRoueter, roles);
          commit('SET_ROUTES', routes);
          commit('SET_ROLES', roles);
          console.log(process.env.NODE_ENV);
          resetRouter();
          routes.push({
            hidden: true,
            path: '*',
            redirect: '/404'
          });
          router.addRoutes(routes);
          resolve(routes);
        });
      });
    },
    //根据手机号登录
    LoginByPhone: function LoginByPhone(_ref7, userInfo) {
      var commit = _ref7.commit;
      return new Promise(function (resolve) {
        loginByUsername(userInfo.phone, userInfo.code).then(function (res) {
          var data = res.data.data;
          commit('SET_TOKEN', data);
          commit('DEL_ALL_TAG');
          commit('CLEAR_LOCK');
          resolve();
        });
      });
    },
    GetUserInfo: function GetUserInfo(_ref8) {
      var commit = _ref8.commit;
      return new Promise(function (resolve, reject) {
        getUserInfo().then(function (res) {
          var data = res.data.data;
          commit('SET_ROLES', data.roles);
          resolve(data);
        }).catch(function (err) {
          reject(err);
        });
      });
    },
    //刷新token
    RefeshToken: function RefeshToken(_ref9) {
      var state = _ref9.state,
          commit = _ref9.commit;
      return new Promise(function (resolve, reject) {
        refeshToken(state.refeshToken).then(function (res) {
          var data = res.data.data;
          commit('SET_TOKEN', data);
          resolve(data);
        }).catch(function (error) {
          reject(error);
        });
      });
    },
    // 登出
    LogOut: function LogOut(_ref10) {
      var commit = _ref10.commit,
          dispatch = _ref10.dispatch;
      return new Promise(function (resolve, reject) {
        logout().then(function () {
          commit('SET_TOKEN', '');
          commit('SET_MENU', []);
          commit('SET_ROLES', []);
          commit('DEL_ALL_TAG');
          commit('CLEAR_LOCK'); // commit('SET_ACTIVE_PROJECT', {}) 

          dispatch('tagsView/delAllVisitedViews');
          removeToken();
          resolve();
        }).catch(function (error) {
          reject(error);
        });
      });
    },
    //注销session
    FedLogOut: function FedLogOut(_ref11) {
      var commit = _ref11.commit;
      return new Promise(function (resolve) {
        commit('SET_TOKEN', '');
        commit('SET_MENU', []);
        commit('SET_ROLES', []);
        commit('DEL_ALL_TAG');
        commit('CLEAR_LOCK');
        removeToken();
        resolve();
      });
    },
    GetTopMenu: function GetTopMenu() {
      return new Promise(function (resolve) {
        getTopMenu().then(function (res) {
          var data = res.data.data || [];
          resolve(data);
        });
      });
    },
    //获取系统菜单
    GetMenu: function GetMenu(_ref12, parentId) {
      var commit = _ref12.commit,
          dispatch = _ref12.dispatch;
      return new Promise(function (resolve) {
        getMenu(parentId).then(function (res) {
          var data = res.data.data;
          var menu = deepClone(data);
          menu.forEach(function (ele) {
            addPath(ele, true);
          });
          commit('SET_MENU', menu);
          dispatch('GetButtons');
          resolve(menu);
        });
      });
    },
    // 加载校园信息
    GetCampusList: function GetCampusList() {
      return new Promise(function (resolve) {
        getCampusList().then(function (res) {
          var data = res.data.data;
          resolve(data);
        });
      });
    }
  },
  mutations: {
    SET_TOKEN: function SET_TOKEN(state, token) {
      setToken(token);
      state.token = token;
      setStore({
        name: 'token',
        content: state.token,
        type: 'session'
      });
    },
    SET_USERIFNO: function SET_USERIFNO(state, userInfo) {
      state.userInfo = userInfo;
      setStore({
        name: 'userInfo',
        content: state.userInfo
      });
    },
    SET_MENU: function SET_MENU(state, menu) {
      state.menu = menu;
      setStore({
        name: 'menu',
        content: state.menu,
        type: 'session'
      });
    },
    SET_MENU_ALL: function SET_MENU_ALL(state, menuAll) {
      state.menuAll = menuAll;
    },
    SET_ACTIVE_PROJECT: function SET_ACTIVE_PROJECT(state, project) {
      if (Object.keys(project).length) {
        state.activeProject = project;
        setStore({
          name: 'activeProject',
          content: project
        });
      } else {
        state.activeProject = {};
        removeStore({
          name: 'activeProject'
        });
      }
    },
    SET_PAGE_SIZE: function SET_PAGE_SIZE(state, pageSizeList) {
      // state.pageSizeList = pageSizeList;
      if (Object.keys(pageSizeList).length) {
        state.pageSizeList = pageSizeList;
        setStore({
          name: 'pageSizeList',
          content: pageSizeList
        });
      } else {
        state.pageSizeList = {};
        removeStore({
          name: 'pageSizeList'
        });
      }
    },
    SET_ROUTES: function SET_ROUTES(state, routes) {
      state.routes = routes;
    },
    SET_ROLES: function SET_ROLES(state, roles) {
      state.roles = roles;
    },
    SET_BTNRULES: function SET_BTNRULES(state, btnRules) {
      state.btnRules = btnRules;
    },
    SET_PERMISSION: function SET_PERMISSION(state, permission) {
      var result = [];

      function getCode(list) {
        list.forEach(function (ele) {
          if (_typeof(ele) === 'object') {
            var chiildren = ele.children;
            var code = ele.code;

            if (chiildren) {
              getCode(chiildren);
            } else {
              result.push(code);
            }
          }
        });
      }

      getCode(permission);
      state.permission = {};
      result.forEach(function (ele) {
        state.permission[ele] = true;
      });
      setStore({
        name: 'permission',
        content: state.permission,
        type: 'session'
      });
    },
    //储存版本信息
    SET_SYSTEM_VERISON: function SET_SYSTEM_VERISON(state, parmars) {
      state.systemVersion = parmars;
      setStore({
        name: 'getSystemVersion',
        content: parmars
      });
    }
  }
};
export default user;